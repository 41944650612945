export const cutABI = [
  "constructor(string contractName, string contractSymbol)",
  "event Contribution(address indexed _contributor, uint16 indexed _project, uint256 indexed _amount)",
  "event InternalApproval(address indexed owner, address indexed spender, uint256 amount)",
  "event InternalTransfer(address indexed from, address indexed to, uint256 amount)",
  "event Paused(address account)",
  "event ProjectCreated(uint16 indexed _project)",
  "event RetireIntent(address indexed _owner, uint256 indexed _amount)",
  "event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)",
  "event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)",
  "event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)",
  "event SoftCapChanged(uint256 indexed _amount)",
  "event TokensAdded(uint16 indexed _project, uint256 indexed _amount)",
  "event TokensDispersed(address indexed _owner, uint256 indexed _amount)",
  "event Unpaused(address account)",
  "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
  "function MINTER_ROLE() view returns (bytes32)",
  "function OPERATOR_ROLE() view returns (bytes32)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address proxySender, address spender, uint256 amount) returns (bool)",
  "function balanceOf(address account) view returns (uint256)",
  "function decreaseAllowance(address proxySender, address spender, uint256 subtractedValue) returns (uint256)",
  "function disperse(address account, uint256 amount)",
  "function getRoleAdmin(bytes32 role) view returns (bytes32)",
  "function getRoleMember(bytes32 role, uint256 index) view returns (address)",
  "function getRoleMemberCount(bytes32 role) view returns (uint256)",
  "function grantRole(bytes32 role, address account)",
  "function hasRole(bytes32 role, address account) view returns (bool)",
  "function increaseAllowance(address proxySender, address spender, uint256 addedValue) returns (uint256)",
  "function mint(uint16 projectId, uint256 amount)",
  "function name() view returns (string)",
  "function newCarbonProject() returns (uint16 projectId)",
  "function paused() view returns (bool)",
  "function renounceRole(bytes32 role, address account)",
  "function retire(address account, uint16 projectId, uint256 amount)",
  "function revokeRole(bytes32 role, address account)",
  "function setPaused(bool pauseState)",
  "function setProductionAddress(uint8 uaddr_type, address newAddress)",
  "function setSoftCap(uint256 amount)",
  "function signalRetireIntent(address proxySender, uint256 retirementAmount)",
  "function symbol() view returns (string)",
  "function totalSupply() view returns (uint256)",
  "function transfer(address proxySender, address recipient, uint256 amount) returns (bool)",
  "function transferFrom(address proxySender, address from, address recipient, uint256 amount) returns (uint256)",
];

export const cutProxyABI = [
  "constructor()",
  "event Approval(address indexed owner, address indexed spender, uint256 value)",
  "event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)",
  "event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)",
  "event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)",
  "event Transfer(address indexed from, address indexed to, uint256 value)",
  "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
  "function LOGGER_ROLE() view returns (bytes32)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function announceDispersed(address recipient, uint256 amount) returns (bool)",
  "function announceRetirement(address contributor, uint256 amount) returns (bool)",
  "function approve(address spender, uint256 amount) returns (bool)",
  "function balanceOf(address account) view returns (uint256)",
  "function decimals() view returns (uint8)",
  "function decreaseAllowance(address spender, uint256 subtractedValue) returns (bool)",
  "function getProductionLibrary() view returns (address)",
  "function getRoleAdmin(bytes32 role) view returns (bytes32)",
  "function getRoleMember(bytes32 role, uint256 index) view returns (address)",
  "function getRoleMemberCount(bytes32 role) view returns (uint256)",
  "function grantRole(bytes32 role, address account)",
  "function hasRole(bytes32 role, address account) view returns (bool)",
  "function increaseAllowance(address spender, uint256 addedValue) returns (bool)",
  "function name() view returns (string)",
  "function renounceRole(bytes32 role, address account)",
  "function revokeRole(bytes32 role, address account)",
  "function setProductionLibrary(address newLibrary)",
  "function signalRetireIntent(uint256 retirementAmount)",
  "function symbol() view returns (string)",
  "function totalSupply() view returns (uint256)",
  "function transfer(address recipient, uint256 amount) returns (bool)",
  "function transferFrom(address from, address recipient, uint256 amount) returns (bool)",
];

export const projectsDbABI = [
  "function numProjects() view returns (uint16)",
  "function projectExists(uint16) view returns (bool)",
  "function projectRetired(uint16) view returns (uint256)",
  "function projectUnretired(uint16) view returns (uint256)",
];

export const cutDbABI = [
  "constructor(uint256 initHistoricalSupply, uint256 initLivingSupply, uint256 initUnmatchedAvailable, uint256 initTotalRetired, uint256 initSoftCap)",
  "event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)",
  "event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)",
  "event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)",
  "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
  "function WRITING_ROLE() view returns (bytes32)",
  "function addWriteAccess(address writer)",
  "function decrementUnmatchedAvailable(uint256 amount) returns (uint256)",
  "function forwardMigration(address newDBaddress, uint16 maxWork)",
  "function getRoleAdmin(bytes32 role) view returns (bytes32)",
  "function getRoleMember(bytes32 role, uint256 index) view returns (address)",
  "function getRoleMemberCount(bytes32 role) view returns (uint256)",
  "function grantRole(bytes32 role, address account)",
  "function hasRole(bytes32 role, address account) view returns (bool)",
  "function historicalSupply() view returns (uint256)",
  "function incrementRetired(uint256 amount) returns (uint256)",
  "function incrementUnmatchedAvailable(uint256 amount) returns (uint256)",
  "function livingSupply() view returns (uint256)",
  "function lockedBalance(address account) view returns (uint256)",
  "function numUsers() view returns (uint256)",
  "function renounceRole(bytes32 role, address account)",
  "function revokeRole(bytes32 role, address account)",
  "function revokeWriteAccess(address writer)",
  "function setLockedBalance(address account, uint256 amount)",
  "function setSoftCap(uint256 amount) returns (uint256)",
  "function setUnmatchedBalance(address account, uint256 amount)",
  "function softCap() view returns (uint256)",
  "function totalRetired() view returns (uint256)",
  "function unmatchedAvailable() view returns (uint256)",
  "function unmatchedBalance(address account) view returns (uint256)",
];
