import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExternalLinkIcon } from "@heroicons/react/solid";
import * as React from "react";
import { useEthers } from "@usedapp/core";

export function ConnectionModal({ shown, onClose }) {
  const [connecting, setConnecting] = useState(false);
  const { activateBrowserWallet, account, error } = useEthers();
  const handleConnect = async () => {
    setConnecting(true);
    await activateBrowserWallet();
  };

  useEffect(() => {
    if (error || account) {
      setConnecting(false);
    }
    if (account) {
      onClose();
    }
  }, [account, error, onClose]);

  return (
    <Transition.Root show={shown} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Connect Wallet
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Connect a wallet to see your own contributions and start
                      retiring CUT.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                {!(window.ethereum && window.ethereum.isMetaMask) ? (
                  <a
                    className="btn btn-primary inline-flex justify-center w-full"
                    href="https://metamask.io/download.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get MetaMask <ExternalLinkIcon className="ml-1 w-4" />
                  </a>
                ) : !error || connecting ? (
                  <button
                    onClick={handleConnect}
                    className="btn btn-primary inline-flex justify-center w-full"
                    disabled={connecting}
                  >
                    {connecting ? "Connecting..." : "Connect Wallet"}
                  </button>
                ) : (
                  <button
                    onClick={handleConnect}
                    className="btn btn-danger inline-flex justify-center w-full"
                    disabled={connecting}
                  >
                    Could not connect. Try again?
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
