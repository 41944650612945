import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { CertificateScreen } from "./screens/certificate";
import { NetworkScreen } from "./screens/network";
import { TransactionsScreen } from "./screens/transactions";
import { AppHeader } from "./components/app-header";
import { useEthers } from "@usedapp/core";
import { ConnectionModal } from "./components/connection-modal";
import { ProjectsScreen } from "./screens/projects";
import { NetworkContext } from "./context/network-context";
import { getChainColor, getChainName, supportedChains } from "./utils/chain";
import chroma from "chroma-js";

// A wrapper for a component that requires a wallet
function RequiresConnection({ children, showConnection }) {
  const { account } = useEthers();
  if (!account) {
    return (
      <div className="max-w-sm sm:max-w-xl mx-auto mt-6">
        <div className="text-center border-2 border-gray-300 border-dashed p-5 rounded-xl">
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            Wallet not found
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Connect a wallet to see your contributions and to start retiring
            carbon.
          </p>
          <div className="mt-6">
            <button
              type="button"
              className="btn btn-primary"
              onClick={showConnection}
            >
              Connect Wallet
            </button>
          </div>
        </div>
      </div>
    );
  }
  return children;
}

function RequiresNetworkContext({ children }) {
  const { selectedNetwork, setSelectedNetwork } =
    React.useContext(NetworkContext);

  // If a network has been selected, render the wrapped component
  if (selectedNetwork) {
    return children;
  }

  return (
    <div className="max-w-sm sm:max-w-xl mx-auto mt-6">
      <div className="text-center border-2 border-gray-300 border-dashed p-5 rounded-xl">
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          Network not selected
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Select a network to see your contributions or project details and to
          start retiring carbon.
        </p>
        <div className="mt-6">
          {supportedChains.map((chain) => {
            const color = getChainColor(chain);
            const borderColor = chroma(color).darken().hex(); // This will darken the color

            return (
              <button
                key={chain}
                type="button"
                className="btn btn-primary mr-2"
                style={{
                  backgroundColor: color,
                  borderColor: borderColor, // Set the border color to the darker color
                }}
                onClick={() => setSelectedNetwork(chain)}
              >
                {getChainName(chain)}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function App() {
  const [connectModalOpen, setConnectModalOpen] = useState(false);

  let location = useLocation();

  useEffect(() => {
    if (window.goatcounter) {
      window.goatcounter.count({
        path: location.pathname + location.search + location.hash,
      });
    }
  }, [location]);

  return (
    <div className="min-h-screen bg-white">
      <AppHeader showConnection={() => setConnectModalOpen(true)} />
      <ConnectionModal
        shown={connectModalOpen}
        onClose={() => setConnectModalOpen(false)}
      />
      <main className="max-w-5xl mx-auto">
        <Routes>
          <Route path="/" element={<Navigate to={"/network/"} replace />} />
          <Route path="/network/" element={<NetworkScreen />} />
          <Route
            path="/contributions/:contributionId/certificate/"
            element={<CertificateScreen />}
          />
          <Route
            path="/contributions/:networkPreselect/:contributionId/certificate"
            element={<CertificateScreen />}
          />
          <Route
            path="/transactions/"
            element={
              <RequiresConnection
                showConnection={() => setConnectModalOpen(true)}
              >
                <TransactionsScreen />
              </RequiresConnection>
            }
          />

          <Route
            path="/projects/"
            element={
              <RequiresNetworkContext>
                <ProjectsScreen />
              </RequiresNetworkContext>
            }
          />

          <Route
            path="/projects/:networkPreselect/:projectIdPreselect"
            element={<ProjectsScreen />}
          />
        </Routes>
      </main>
    </div>
  );
}

export default App;
