import React, { useContext, useMemo } from "react";
import { formatNumberLocale } from "../utils/format";
import { getProject } from "../api/projects";
import { capitalize } from "lodash";
import {
  differenceInCalendarMonths,
  format,
  formatDistance,
  intlFormat,
} from "date-fns";
import { useContributionsQuery } from "../api/contributions";
import { EtherscanAddrLink, EtherscanTxLink } from "./etherscan-link";
import { Table } from "./table";
import { NetworkContext } from "../context/network-context";
import { Tooltip } from "react-tooltip";
import { getChainName } from "../utils/chain";
import { ShareComponent } from "./share-icon";

const Project = ({ projectId }: { projectId: number }) => {
  const { providerBuilder } = useContext(NetworkContext);
  const { provider, chainId } = providerBuilder();
  const project = getProject(chainId, projectId);
  const contributions = useContributionsQuery(provider, chainId);
  const now = useMemo(() => new Date(), []);
  const contributionData = useMemo(() => {
    if (!contributions.data) return [];
    return contributions.data.filter(
      (contribution) => contribution.project === projectId
    );
  }, [contributions, projectId]);
  const columns = useMemo(
    () => [
      {
        Header: "Age",
        accessor: "timestamp",
        Cell: ({ value }) => (
          <span title={format(value, "yyyy-MM-dd")}>
            {formatDistance(value, now)}
          </span>
        ),
      },
      {
        Header: "Tx",
        accessor: "id",
        Cell: ({ value }) => (
          <EtherscanTxLink txnHash={value} chainId={chainId} />
        ),
      },
      {
        Header: "Contributor",
        accessor: "contributor",
        Cell: ({ value }) => (
          <EtherscanAddrLink address={value} chainId={chainId} />
        ),
      },
      {
        Header: "Amount",
        accessor: "amount",
        headerClassName: "text-right",
        cellClassName: "text-right",
        Cell: ({ value }) => formatNumberLocale(value),
      },
    ],
    [now, chainId]
  );

  const headerWrapProps = {
    // Expects an image of size 3056x1370
    className:
      "bg-center bg-no-repeat bg-[center_top_-13rem] bg-[length:250%] sm:bg-center sm:bg-cover relative",
    style: {
      backgroundImage: `url('/${project.imageUrl}')`,
    },
  };
  const headerProps = {
    className:
      "bg-gradient-to-b from-transparent to-white pt-16 sm:pt-36 lg:pt-48",
  };

  const start = new Date(project.reductionsPeriodStart);
  const end = new Date(project.reductionsPeriodEnd);

  const months = differenceInCalendarMonths(end, start);

  const fullUrl = `${window.location.origin}/projects/${getChainName(
    chainId
  )}/${projectId}`;

  return (
    <>
      <div className={headerWrapProps.className} style={headerWrapProps.style}>
        <ShareComponent shareText={fullUrl} />

        <header className={headerProps.className}>
          <div className="px-4 py-5 sm:px-6">
            <h1
              className="text-2xl sm:text-3xl font-bold text-pine"
              id={`project-${project.id}`}
            >
              {project.name}
            </h1>
            <p className="mt-1 max-w-2xl text-sm text-pine">
              {project.description}
            </p>
          </div>
        </header>
      </div>
      <div className="mt-2 overflow-hidden">
        <div className="px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className={cssClasses.dlItem}>
              <dt className={cssClasses.dlTerm}>Location</dt>
              <dd className={cssClasses.dlDescription}>
                {project.reductionsLocation}
              </dd>
            </div>

            <div className={cssClasses.dlItem}>
              <dt className={cssClasses.dlTerm}>Project Type</dt>
              <dd className={cssClasses.dlDescription}>
                {project.projectType}
              </dd>
            </div>

            <div className={cssClasses.dlItem}>
              <dt className={cssClasses.dlTerm}>Offset Category</dt>
              <dd className={cssClasses.dlDescription}>
                {capitalize(project.projectCategory)}
              </dd>
            </div>

            <div className={cssClasses.dlItem}>
              <dt className={cssClasses.dlTerm}>Protocol</dt>
              <dd className={cssClasses.dlDescription}>
                {project.certificationProtocol}
              </dd>
            </div>

            <div className={cssClasses.dlItem}>
              <dt className={cssClasses.dlTerm}>Certifier</dt>
              <dd className={cssClasses.dlDescription}>
                {project.certifier
                  ? [project.certifier, project.certifierLocation].join(", ")
                  : "-"}
              </dd>
            </div>

            <div className={cssClasses.dlItem}>
              <dt className={cssClasses.dlTerm}>Verifier</dt>
              <dd className={cssClasses.dlDescription}>
                {project.verifier}, {project.verifierLocation}
              </dd>
            </div>

            <div className={cssClasses.dlItem}>
              <dt className={cssClasses.dlTerm}>Reductions Period</dt>
              <dd className={cssClasses.dlDescription}>
                <span id={`project-reductions-${project.id}`}>
                  for {months + 1} months between {start.getFullYear()} and{" "}
                  {end.getFullYear()}
                </span>
                <Tooltip
                  anchorSelect={`#project-reductions-${project.id}`}
                  content={` from ${intlFormat(project.reductionsPeriodStart)} 
                                                     to ${intlFormat(
                                                       project.reductionsPeriodEnd
                                                     )}`}
                />
              </dd>
            </div>

            <div className={cssClasses.dlItem}>
              <dt className={cssClasses.dlTerm}>Reductions (tCO&#8322;e)</dt>
              <dd className={cssClasses.dlDescription}>
                {formatNumberLocale(project.totalReductions)}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="px-4 pt-8 pb-5 sm:px-6">
        <h2 className="text-xl sm:text-2xl font-bold text-pine">
          Contributions
        </h2>
      </div>
      <div className="mb-4 overflow-hidden">
        <Table
          columns={columns}
          ariaLabel={`project-${projectId}-contributions`}
          data={contributionData}
          initialSortBy={[{ id: "timestamp", desc: true }]}
          isLoading={contributions.isLoading}
        />
      </div>
    </>
  );
};

const cssClasses = {
  dlItem: "py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
  dlTerm: "text-sm font-medium text-gray-500",
  dlDescription: "mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2",
};

export { Project };
