import { usePagination, useSortBy, useTable } from "react-table";
import { combineClassNames } from "../utils/style";
import { useMemo } from "react";

export function Table({
  columns,
  data,
  ariaLabel,
  initialSortBy,
  isLoading = false,
}) {
  const rowCount = isLoading ? 0 : data.length;
  const sortBy = useMemo(() => initialSortBy, [initialSortBy]);
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    nextPage,
    page,
    pageCount,
    prepareRow,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy },
    },
    useSortBy,
    usePagination
  );
  return (
    <div className="block max-w-full overflow-hidden sm:shadow sm:rounded-lg">
      <div className="block max-w-full overflow-x-auto overflow-y-hidden">
        <table
          className="w-full divide-y divide-gray-200"
          aria-label={ariaLabel}
          {...getTableProps()}
        >
          <thead className="bg-gray-50">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    scope="col"
                    className={combineClassNames(
                      column.headerClassName,
                      "px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    )}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps}
            className="bg-white divide-y divide-gray-200"
          >
            {isLoading ? (
              <tr>
                <td className="px-3 md:px-6 py-4 text-sm text-gray-500">
                  Loading&hellip;
                </td>
              </tr>
            ) : data.length ? (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        className={combineClassNames(
                          cell.column.cellClassName,
                          "px-3 md:px-6 py-4 text-sm text-gray-500 text-ellipsis overflow-x-hidden whitespace-nowrap"
                        )}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="px-3 md:px-6 py-4 text-sm text-gray-500">
                  Nothing to show.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <nav
        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing{" "}
            {!!rowCount && (
              <>
                <span className="font-medium">{pageIndex * pageSize + 1}</span>{" "}
                to{" "}
                <span className="font-medium">
                  {Math.min((pageIndex + 1) * pageSize, rowCount)}
                </span>{" "}
                of{" "}
              </>
            )}
            <span className="font-medium">{rowCount}</span> results
          </p>
        </div>
        <div className="flex-1 flex justify-between sm:justify-end">
          <button
            onClick={previousPage}
            disabled={!rowCount || pageIndex === 0}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <button
            onClick={nextPage}
            disabled={!rowCount || pageIndex + 1 === pageCount}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </nav>
    </div>
  );
}
