import { ChainId } from "@usedapp/core";
import { useQuery } from "react-query";
import { getContractsForNetwork } from "../cut-contract/addresses";
import { toCut } from "../cut-contract";
import { range } from "lodash";

interface IProject {
  id: number;
  available: number;
  retired: number;
  total: number;
  details: IProjectDetails;
}

interface IProjectDetails {
  id: number;
  imageUrl: string;
  name: string;
  shortName: string;
  description: string;
  totalReductions: number;
  projectType: string;
  projectCategory: "avoidance" | "reduction";
  certifier?: string;
  certifierLocation?: string;
  certificationProtocol: string;
  verifier: string;
  verifierLocation: string;
  reductionsLocation: string;
  reductionsPeriodStart: Date;
  reductionsPeriodEnd: Date;
}

export const projects: Partial<Record<ChainId, IProjectDetails[]>> = {
  [ChainId.Mainnet]: [
    // https://www.csaregistries.ca/cleanprojects/masterprojectdetails_e.cfm?pid=318
    {
      id: 1,
      imageUrl: "images/project_1.jpg",
      name: "St. Félicien Biomass Cogeneration Plant",
      shortName: "Biomass Cogeneration",
      description:
        "The St. Félicien Biomass Cogeneration Plant project uses biomass from the local saw mill for thermal production, displacing fossil fuels for both electricity generation and drying lumber, and avoiding the methane emissions associated with decomposing biomass.",
      totalReductions: 500000,
      projectType: "Methane Venting Reduction",
      projectCategory: "avoidance",
      certifier: null,
      certifierLocation: null,
      certificationProtocol: "ISO 14064-2:2006",
      verifier: "Internat Energy Solutions",
      verifierLocation: "Toronto, ON, Canada",
      reductionsLocation: "Saint-Félicien, Quebec, Canada",
      reductionsPeriodStart: new Date(2011, 1, 1),
      reductionsPeriodEnd: new Date(2013, 12, 31),
    },
    // https://www.csaregistries.ca/cleanprojects/masterprojectdetails_e.cfm?pid=236
    {
      id: 2,
      name: "Hwy. 101 Landfill Gas Capture",
      shortName: "Landfill Gas Capture",
      description:
        "The Highway 101 Landfill Gas Capture project captures methane emissions from a decommissioned landfill and uses them to produce electricity.",
      imageUrl: "images/project_2.jpg",
      certifier: "3P Analysis and Consulting",
      certifierLocation: "Guelph, ON, Canada",
      totalReductions: 45822.4,
      projectType: "Landfill Gas Capture",
      projectCategory: "avoidance",
      certificationProtocol: "ISO 14064-2:2006",
      verifier: "Enviro-access",
      verifierLocation: "Sherbrooke, QC, Canada",
      reductionsLocation: "Sackville, NS, Canada",
      reductionsPeriodStart: new Date(2017, 1, 1),
      reductionsPeriodEnd: new Date(2019, 12, 31),
    },
  ],
  [ChainId.Arbitrum]: [
    // https://www.csaregistries.ca/cleanprojects/masterprojectdetails_e.cfm?pid=318
    {
      id: 1,
      imageUrl: "images/project_1.jpg",
      name: "Hwy. 101 Landfill Gas Capture",
      shortName: "Landfill Gas Capture (Arb)",
      description:
        "The Highway 101 Landfill Gas Capture project captures methane emissions from a decommissioned landfill and uses them to produce electricity.",
      totalReductions: 19299,
      projectType: "Methane Venting Reduction",
      projectCategory: "avoidance",
      certifier: null,
      certifierLocation: null,
      certificationProtocol: "ISO 14064-3:2006",
      verifier: "Enviro-access",
      verifierLocation: "Sherbrooke, QC, Canada",
      reductionsLocation: "Sackville, NS, Canada",
      reductionsPeriodStart: new Date(2016, 3, 1),
      reductionsPeriodEnd: new Date(2016, 12, 31),
    },
  ],
};

export function useProjectsQuery(provider, chainId: ChainId) {
  return useQuery(
    ["projects", provider.name, chainId],
    async (): Promise<IProject[]> => {
      let { projectsDb } = getContractsForNetwork(chainId);
      projectsDb = projectsDb.connect(provider);

      // Get all the projects and their balances
      const numProjects = await projectsDb.numProjects();
      return await Promise.all(
        range(1, numProjects + 1).map(async (id) => {
          const retired = await projectsDb.projectRetired(id);
          const available = await projectsDb.projectUnretired(id);
          return {
            id,
            retired: toCut(retired),
            available: toCut(available),
            total: toCut(retired.add(available)),
            details: getProject(chainId, id),
          };
        })
      );
    },
    {
      refetchInterval: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
}

export function getProject(
  chainId: ChainId,
  projectId: number | string
): IProjectDetails {
  if (typeof projectId === "string") {
    projectId = parseInt(projectId);
  }
  return projects[chainId][projectId - 1];
}
