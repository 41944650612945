import { ChainId, DEFAULT_SUPPORTED_CHAINS } from "@usedapp/core";

export const supportedChains: ChainId[] = [ChainId.Mainnet, ChainId.Arbitrum];

export function getChainName(chainId: ChainId) {
  const name = DEFAULT_SUPPORTED_CHAINS.find(
    (network) => network.chainId === chainId
  )?.chainName;

  return name === "Mainnet" ? "Ethereum" : name;
}

export const ChainColors = {
  [ChainId.Mainnet]: "#627EEA",
  [ChainId.Arbitrum]: "#28a0f0",
};

export function getChainColor(chainId) {
  return ChainColors[chainId] || "#000000";
}
