import { Menu, Transition } from "@headlessui/react";
import { Identicon } from "./identicon";
import React, { Fragment } from "react";
import { combineClassNames } from "../utils/style";
import { ChainId } from "@usedapp/core";
import { getChainName } from "../utils/chain";

interface IAccountMenuProps {
  account: string;
  deactivate: () => void;
  chainId?: ChainId;
}

export function AccountMenuDesktop({ account, deactivate }: IAccountMenuProps) {
  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
          <span className="sr-only">Open user menu</span>
          {/* Hard-coding diameter to 2rem at 16px base font size */}
          <Identicon diameter={32} account={account} />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <span
                onClick={deactivate}
                className={combineClassNames(
                  active ? "bg-gray-100" : "",
                  "cursor-pointer block px-4 py-2 text-sm text-gray-700"
                )}
              >
                Disconnect Metamask
              </span>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export function AccountMenuMobile({
  account,
  chainId,
  deactivate,
}: IAccountMenuProps) {
  return (
    <>
      <div className="flex items-center px-4">
        <div className="shrink-0">
          <Identicon diameter={40} account={account} />
        </div>
        <div className="ml-3 w-1/2">
          <div className="text-base font-medium text-gray-800 truncate">
            {account}
          </div>
          {chainId && (
            <div className="text-sm font-medium text-gray-500">
              {getChainName(chainId)}
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 space-y-1">
        <span
          onClick={deactivate}
          className="cursor-pointer block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
        >
          Disconnect Metamask
        </span>
      </div>
    </>
  );
}
