import React from "react";
import { ExternalLinkIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import { useEthers } from "@usedapp/core";
import { combineClassNames } from "../utils/style";
import { AccountMenuDesktop, AccountMenuMobile } from "./account-menu";
import { CutLogoTypeColor } from "./cut-logo";
import { NetworkMenuDesktop, NetworkMenuMobile } from "./network-menu";

interface INavigationItem {
  name: string;
  href: string;
  hide?: boolean;
  target?: "_self" | "_blank";
}

export function AppHeader({ showConnection }) {
  // The header directly controls ethers, so we can use it here.
  const { account, chainId, deactivate } = useEthers();
  const { pathname } = useLocation();
  const navigationItems: INavigationItem[] = [
    {
      name: "Network",
      href: "/network/",
    },
    {
      name: "Projects",
      href: "/projects/",
    },
    {
      name: "My Activity",
      href: "/transactions/",
    },
  ];
  return (
    <Disclosure
      as="nav"
      className="bg-white border-b border-gray-200 print:hidden"
    >
      {({ open }) => (
        <>
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="shrink-0 flex items-center">
                  <CutLogoTypeColor className="block h-8 w-auto" />
                </div>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {navigationItems.map((item) => {
                    return !item.hide ? (
                      <Link
                        key={item.name}
                        to={item.href}
                        target={item.target}
                        rel={
                          item.target === "_blank" ? "noreferrer" : undefined
                        }
                        className={combineClassNames(
                          pathname.startsWith(item.href)
                            ? "border-primary-500 text-gray-900"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        )}
                        aria-current={
                          pathname.startsWith(item.href) ? "page" : undefined
                        }
                      >
                        {item.name}
                        {item.target && item.target !== "_self" ? (
                          <ExternalLinkIcon className="ml-2 w-4" />
                        ) : null}
                      </Link>
                    ) : null;
                  })}
                  <a
                    href="https://www.cut.eco/faq"
                    target={"_blank"}
                    className={
                      "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }
                  >
                    About CUT <ExternalLinkIcon className="ml-2 w-4" />
                  </a>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <NetworkMenuDesktop deactivate={deactivate} />
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {account ? (
                  <AccountMenuDesktop
                    account={account}
                    deactivate={deactivate}
                  />
                ) : (
                  <button className="btn" onClick={showConnection}>
                    Connect Wallet
                  </button>
                )}
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navigationItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  target={item.target}
                  rel={item.target === "_blank" ? "noreferrer" : undefined}
                  className={combineClassNames(
                    item.href === pathname
                      ? "bg-primary-50 border-primary-500 text-primary-700"
                      : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                    "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  )}
                  aria-current={item.href === pathname ? "page" : undefined}
                >
                  <div className="flex align-baseline">
                    {item.name}
                    {item.target && item.target !== "_self" ? (
                      <ExternalLinkIcon className="ml-1 w-4" />
                    ) : null}
                  </div>
                </Link>
              ))}
            </div>
            <div className="py-3 border-t border-gray-200">
              <NetworkMenuMobile deactivate={deactivate} />
            </div>
            <div className="py-3 border-t border-gray-200">
              {account ? (
                <AccountMenuMobile
                  account={account}
                  deactivate={deactivate}
                  chainId={chainId}
                />
              ) : window.ethereum && window.ethereum.isMetaMask ? (
                <div className="px-4">
                  <button className="btn" onClick={showConnection}>
                    Connect Wallet
                  </button>
                </div>
              ) : (
                <div className="px-4">
                  <a
                    className="btn"
                    href="https://metamask.io/download.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get MetaMask <ExternalLinkIcon className="ml-1 w-4" />
                  </a>
                </div>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

/**
 * Use this header when there's no wallet
 */
export function AppHeaderNoConnection() {
  return (
    <div className="bg-white border-b border-gray-200">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="shrink-0 flex items-center">
              <CutLogoTypeColor />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
