import styled from "styled-components";
import { RetirementStatuses } from "../api/account";

const Circle = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${(props) => props.color};
  border-radius: 0.375rem;
`;

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export function Status({ status }: { status: RetirementStatuses }) {
  return (
    <Circle
      color={
        status === RetirementStatuses.RETIRED
          ? "limegreen"
          : status === RetirementStatuses.PENDING
          ? "gold"
          : "grey"
      }
      title={capitalize(status)}
    />
  );
}
