import { useQuery } from "react-query";
import { ethers } from "ethers";
import { ChainId } from "@usedapp/core";
import { toCut } from "../cut-contract";
import { fetchContributions } from "./utils";
import { JsonRpcProvider } from "@ethersproject/providers";

export interface IContribution {
  id: string;
  contributor: string;
  amount: number;
  project: number;
  timestamp: Date;
  event?: ethers.Event;
  chainId?: ChainId;
}

export async function executeContributionsQuery(
  provider: JsonRpcProvider,
  chainId: ChainId,
  account?
) {
  const events = await fetchContributions(provider, chainId);

  // We want to minimize JSON RPC calls, so ensure blocks are only queried
  // once.
  let blocks;
  const eventByBlockId = Object.fromEntries(
    events.map((event) => [event.blockHash, event])
  );
  if (account || provider.connection.url === "metamask") {
    // Not all wallets support batching directly, so let them handle
    // optimizing calls.
    const blocksBatch = Object.values(eventByBlockId).map(async (event) => {
      return [event.blockHash, await event.getBlock()];
    });
    blocks = Object.fromEntries(await Promise.all(blocksBatch));
  } else {
    // Alchemy supports batch calls, thankfully treating them as one billed
    // request instead of N. See https://www.jsonrpc.org/specification#batch
    const requests = Object.keys(eventByBlockId).map((blockId, id) => ({
      id,
      jsonrpc: "2.0",
      method: "eth_getBlockByHash",
      params: [blockId, false],
    }));
    const blocksBatchResponse = await fetch(provider.connection.url, {
      method: "POST",
      body: JSON.stringify(requests),
    });
    const blocksBatch = await blocksBatchResponse.json();
    blocks = Object.fromEntries(
      blocksBatch.map((batchEntry) => [
        batchEntry.result.hash,
        batchEntry.result,
      ])
    );
  }

  return Promise.all(
    events.map(async (event): Promise<IContribution> => {
      const block = blocks[event.blockHash];

      let timestamp: Date;
      if (typeof block === "undefined") {
        timestamp = new Date();
      } else {
        timestamp = new Date(parseInt(block.timestamp) * 1000);
      }

      return {
        event,
        timestamp,
        amount: toCut(event.args._amount),
        contributor: event.args._contributor,
        project: event.args._project,
        id: event.transactionHash,
      };
    })
  );
}

export const useContributionsQuery = (
  provider: JsonRpcProvider,
  chainId: ChainId
) => {
  const { refetch, isFetched, ...rest } = useQuery(
    ["contributions", chainId],
    async () => {
      return executeContributionsQuery(provider, chainId);
    },
    {
      // At the time the query first runs, the wallet connection hasn't yet been
      // established, leaving no way to distinguish between a connected and
      // disconnected wallet. Rather than introduce a delay before enabling the
      // query (or similar) always use the Alchemy connector for the first
      // fetch; if a wallet is connected then subsequent fetches will go
      // through the wallet.
      enabled: true,
      refetchInterval: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );

  return {
    refetch,
    isFetched,
    ...rest,
  };
};
