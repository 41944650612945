import React, { useState } from "react";
import { useProjectsQuery } from "../api/projects";
import { formatNumberShort } from "../utils/format";
import { CutLogoGrayscale } from "../components/cut-logo";
import { Project } from "../components/project";
import { NetworkContext } from "../context/network-context";
import { useParams } from "react-router-dom";
import { ChainId } from "@usedapp/core";
import { constructReadOnlyProvider } from "../api/utils";
import { BaseProvider } from "@ethersproject/providers";

function getAppropriateProvider(
  providerBuilder,
  networkPreselect: string,
  projectPreselect: string
): {
  provider: BaseProvider;
  chainId: ChainId;
} {
  if (networkPreselect && projectPreselect) {
    // we want to serialize Mainnet as Ethereum for clarity.
    const proposedNetwork =
      networkPreselect === "Ethereum" ? "Mainnet" : networkPreselect;
    // check if the provided url value is even a valid network.
    if (proposedNetwork in ChainId) {
      const chainId = ChainId[proposedNetwork as keyof typeof ChainId];
      const provider = constructReadOnlyProvider(chainId);
      return { provider, chainId };
    }
  }

  const { provider, chainId } = providerBuilder();
  return { provider, chainId };
}

export function ProjectsScreen() {
  const { networkPreselect, projectIdPreselect } = useParams();
  const { providerBuilder } = React.useContext(NetworkContext);
  const { provider, chainId } = getAppropriateProvider(
    providerBuilder,
    networkPreselect,
    projectIdPreselect
  );
  const projects = useProjectsQuery(provider, chainId);

  // kinda hack way to statically link to projects within the app.
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  if (projectIdPreselect && selectedProjectId !== projectIdPreselect) {
    setSelectedProjectId(projectIdPreselect);
  }

  const projectRefs: { [key: string]: React.RefObject<HTMLDivElement> } =
    React.useMemo(() => {
      return {};
    }, []);

  React.useEffect(() => {
    if (selectedProjectId && projectRefs[selectedProjectId]?.current) {
      projectRefs[selectedProjectId].current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [projectRefs, selectedProjectId]);

  if (projects.isLoading) {
    return null;
  }

  projects.data.forEach((project) => {
    if (!projectRefs[project.id]) {
      projectRefs[project.id] = React.createRef();
    }
  });

  return (
    <div className="my-4 sm:my-6 lg:my-8 px-0 sm:px-6 lg:px-8">
      <ul className="my-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2">
        {projects.data.map((project) => (
          <a
            key={project.id}
            href={`#project-${project.id}`}
            className="text-pine hover:text-primary-900 hover:drop-shadow"
          >
            <li className="col-span-1 flex rounded-md">
              <div className="bg-pine flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium sm:rounded-l-md">
                {project.details.id}
              </div>
              <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white sm:rounded-r-md truncate">
                <div className="flex-1 px-4 py-2 text-sm truncate">
                  <span className="font-medium">
                    {project.details.shortName}
                  </span>
                  <p className="text-gray-500">
                    {project.details.reductionsLocation}
                  </p>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <div className="flex">
                    <div className="mr-1">
                      <span className="text-pine">
                        {formatNumberShort(project.available)}
                      </span>
                    </div>
                    <CutLogoGrayscale className="w-4" />
                  </div>
                </div>
              </div>
            </li>
          </a>
        ))}
      </ul>
      {projects.data.map((project) => (
        <div key={project.id} ref={projectRefs[project.id]} className="mt-6">
          <Project projectId={project.id} />
        </div>
      ))}
    </div>
  );
}
