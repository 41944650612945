import React, { useState } from "react";
import {
  CheckCircleIcon,
  ClipboardCopyIcon,
  ShareIcon,
} from "@heroicons/react/solid";

interface IShareComponentProps {
  shareText: string;
}

export function ShareComponent({ shareText }: IShareComponentProps) {
  const [showShareBox, setShowShareBox] = useState(false);
  const [copiedSuccess, setCopiedSuccess] = useState(false);

  const handleShareClick = () => {
    setCopiedSuccess(false);
    setShowShareBox(!showShareBox);
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(shareText);
      setCopiedSuccess(true);
      setTimeout(() => setCopiedSuccess(false), 2500);
    } catch (e) {
      console.log("copy/paste didn't work.");
    }
  };

  return (
    <div className="flex items-center align-top absolute top-0 right-0 mt-4 mr-4">
      {showShareBox && (
        <div className="mr-4 mt-0 relative">
          <input
            type="text"
            readOnly
            value={shareText}
            className="border rounded p-2 w-auto pr-8 pb-1"
          />
          {!copiedSuccess ? (
            <ClipboardCopyIcon
              className="h-6 w-6 absolute right-2 top-2 text-gray-500 hover:text-gray-900 cursor-pointer"
              onClick={handleCopyClick}
            />
          ) : (
            <CheckCircleIcon className="h-6 w-6 absolute right-2 top-2 text-green-500"></CheckCircleIcon>
          )}
        </div>
      )}
      <ShareIcon
        className="h-10 w-10 text-gray-600 cursor-pointer hover:text-gray-900"
        onClick={handleShareClick}
      />
    </div>
  );
}
