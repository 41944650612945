import * as React from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  RetirementStatuses,
  useAccountQuery,
  useRetirementsQuery,
} from "../api/account";
import { RetireCarbonForm } from "../components/retire-carbon-form";
import { formatNumberLocale } from "../utils/format";
import { computeAmountRetired } from "../cut-contract/utils";
import { Table } from "../components/table";
import { format, formatDistance } from "date-fns";
import { Status } from "../components/status";
import { EtherscanTxLink } from "../components/etherscan-link";
import { getProject } from "../api/projects";
import { BadgeCheckIcon } from "@heroicons/react/solid";
import { CutLogoByNetwork } from "../components/cut-logo";
import { NetworkContext } from "../context/network-context";
import { getChainName } from "../utils/chain";
import { HelpTooltip } from "../components/help-tooltip";

function TransactionsScreen() {
  const { providerBuilder, contextLoading } = React.useContext(NetworkContext);
  const { provider, chainId, account } = providerBuilder();

  const accountQuery = useAccountQuery(provider, chainId, account);
  const retirementsQuery = useRetirementsQuery(provider, chainId, account);

  const now = useMemo(() => new Date(), []);

  const transactionsData = useMemo(() => {
    return retirementsQuery.isLoading ||
      retirementsQuery.isError ||
      retirementsQuery.isIdle
      ? []
      : retirementsQuery.data;
  }, [retirementsQuery, chainId, provider, now]);

  const amountRetired = useMemo(() => {
    return computeAmountRetired(transactionsData);
  }, [transactionsData]);

  const transactionColumns = useMemo(
    () => [
      {
        Header: "Age",
        accessor: "timestamp",
        Cell: ({ value }) => (
          <span title={format(value, "yyyy-MM-dd")}>
            {formatDistance(value, now)}
          </span>
        ),
      },
      {
        Header: "Tx",
        accessor: "contribution.id",
        Cell: ({ value }) =>
          value ? (
            <EtherscanTxLink txnHash={value} chainId={chainId} />
          ) : (
            <span>Pending...</span>
          ),
      },
      {
        Header: "Project",
        accessor: "projectId",
        Cell: ({ value }) => (
          <HashLink to={`/projects/#project-${value}`}>
            {getProject(chainId, value)?.name}
          </HashLink>
        ),
      },
      {
        Header: "Amount",
        accessor: "amount",
        headerClassName: "text-right",
        cellClassName: "text-right",
        Cell: ({ value }) => formatNumberLocale(value),
      },
      {
        Header: "Certificate",
        accessor: "status",
        headerClassName: "text-center",
        cellClassName: "flex justify-center",
        Cell: ({ value, cell }) => {
          if (value === RetirementStatuses.RETIRED) {
            return (
              <Link
                to={`/contributions/${cell.row.original.contribution?.id}/certificate`}
                title="View Certificate"
              >
                <BadgeCheckIcon className="w-5 h-5 fill-primary-500" />
              </Link>
            );
          } else {
            return <Status status={value} />;
          }
        },
      },
    ],
    [chainId, now]
  );

  const isLoading =
    contextLoading ||
    [accountQuery, retirementsQuery].some((query) => {
      return query.isLoading || query.isIdle;
    });

  return (
    <>
      <div className="max-w-5xl my-4 sm:my-6 lg:my-8 mx-auto px-0 sm:px-6 lg:px-8">
        <div className="overflow-hidden shadow sm:rounded-lg md:flex md:flex-row">
          <div className="bg-primary-50 px-4 py-5 sm:p-6 md:flex md:w-1/2">
            <div className="flex-col w-full">
              <h1 className="text-xl font-semibold text-primary-800">
                Account{" "}
                <HelpTooltip
                  title={account}
                  description="The account currently linked via Metamask"
                />
              </h1>
              <dl className="pr-5">
                <div className="pt-3 grid grid-cols-2 gap-4 w-full">
                  <dt id="cut-balance">
                    Balance –
                    <CutLogoByNetwork
                      chainId={chainId}
                      className="inline-block h-4 mx-1 mb-0.5"
                    />
                  </dt>
                  <dd
                    className="flex text-right font-semibold align-middle justify-end"
                    aria-labelledby="cut-balance"
                  >
                    {formatNumberLocale(
                      isLoading ? 0 : accountQuery.data.cutBalance
                    )}
                  </dd>
                </div>
                <div className="pt-3 grid grid-cols-2 gap-4 w-full">
                  <dt id="retired-cut">
                    Total Offset –
                    <CutLogoByNetwork
                      chainId={chainId}
                      className="inline-block h-4 mx-1 mb-0.5"
                    />
                  </dt>
                  <dd
                    className="text-right font-semibold"
                    aria-labelledby="retired-cut"
                  >
                    {formatNumberLocale(amountRetired.asCut)}
                  </dd>
                </div>
                <div className="pt-3 sm:grid sm:grid-cols-2 sm:gap-4 w-full">
                  <dt id="retired-kg">Total Offset – kg CO&#8322;e</dt>
                  <dd
                    className="text-right font-semibold"
                    aria-labelledby="retired-kg"
                  >
                    {formatNumberLocale(amountRetired.asKg)}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6 md:flex md:w-1/2">
            <div className="flex-col w-full">
              <h1 className="text-xl font-semibold text-primary-800">
                Retire Carbon on {getChainName(chainId)}
              </h1>
              <RetireCarbonForm />
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-5xl mx-auto px-0 sm:px-6 lg:px-8">
        <div className="py-5 px-4 sm:px-0">
          <h2 className="text-2xl leading-6 font-medium text-pine">
            My Contributions
          </h2>
        </div>
        <Table
          columns={transactionColumns}
          data={transactionsData}
          ariaLabel="transactions"
          initialSortBy={[{ id: "timestamp", desc: true }]}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}

export { TransactionsScreen };
