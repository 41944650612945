import { useMutation, useQuery, useQueryClient } from "react-query";
import { BigNumber, ethers } from "ethers";
import { toCut } from "../cut-contract";
import {
  fetchContributions,
  fetchRetireIntents,
  matchIntentsWithContributions,
} from "./utils";
import { getContractsForNetwork } from "../cut-contract/addresses";
import { ChainId } from "@usedapp/core";
import { BaseProvider, JsonRpcSigner } from "@ethersproject/providers";

export enum RetirementStatuses {
  UNVERIFIED = "unverified", // Retire intent signaled but not verified
  PENDING = "pending", // Retire intent signaled and verified
  RETIRED = "retired", // Retire intent matched with contribution
}

export interface IEventDetails {
  id: string;
  timestamp: Date;
  event: ethers.Event;
}

export interface IRetirement {
  // TransactionResponse.hash of the intent
  id: string;
  // Amount in CUT
  amount: number;
  status: RetirementStatuses;
  // Timestamp of intent (or form submit time for unverified txs)
  timestamp: Date;
  // Timestamp of last update (i.e. form submit, intent or contribution)
  updated: Date;
  intent?: IEventDetails;
  contribution?: IEventDetails;
  projectId?: number;
}

export interface IAccount {
  address: string;
  cutBalance: number;
}

enum QueryNamespace {
  ACCOUNT = "accounts",
  RETIREMENTS = "retirements",
}

async function getEventDetails(event?: ethers.Event): Promise<IEventDetails> {
  if (!event) return;
  const tx = await event.getTransaction();
  const block = await event.getBlock();
  const timestamp = new Date(block.timestamp * 1000);
  return {
    id: tx.hash,
    timestamp,
    event,
  };
}

export async function executeAccountQuery(
  provider: BaseProvider,
  chainId: ChainId,
  account: string
) {
  const { proxy } = getContractsForNetwork(chainId);
  const mCutBalance = await proxy.connect(provider).balanceOf(account);
  return {
    address: account,
    cutBalance: toCut(mCutBalance),
  };
}

export const useAccountQuery = (
  provider: BaseProvider,
  chainId: ChainId,
  account: string,
  enabled?: boolean
) => {
  return useQuery(
    [QueryNamespace.ACCOUNT, chainId, account],
    async (): Promise<IAccount> => {
      return executeAccountQuery(provider, chainId, account);
    },
    { enabled: enabled && !!account }
  );
};

export const useRetirementsQuery = (provider, chainId, account, enabled?) => {
  const queryClient = useQueryClient();
  return useQuery(
    [QueryNamespace.RETIREMENTS, chainId, account],
    async (): Promise<IRetirement[]> => {
      const intents = await fetchRetireIntents(provider, chainId, {
        owner: account,
      });
      const contributions = await fetchContributions(provider, chainId, {
        owner: account,
      });
      const matched = matchIntentsWithContributions(intents, contributions);

      // Pluck any unverified transactions (they're injected when retirement
      // intent is signaled, and won't be returned by querying the network)
      const existingData = queryClient.getQueryData(QueryNamespace.RETIREMENTS);
      const unverifiedRetirements = existingData
        ? (existingData as IRetirement[]).filter(
            (r) => r.status === RetirementStatuses.UNVERIFIED
          )
        : [];

      const retirementIds = new Set();
      const retirements: IRetirement[] = await Promise.all(
        matched.map(async ({ contribution, intent }) => {
          const intentDetails = await getEventDetails(intent);
          const contributionDetails = await getEventDetails(contribution);
          retirementIds.add(intentDetails.id);
          return {
            id: intentDetails.id,
            amount: toCut(intent.args[1]),
            projectId: contribution?.args._project,
            status: Boolean(contribution)
              ? RetirementStatuses.RETIRED
              : RetirementStatuses.PENDING,
            contribution: contributionDetails,
            intent: intentDetails,
            timestamp: intentDetails.timestamp,
            updated: contributionDetails?.timestamp || intentDetails.timestamp,
          };
        })
      );

      // Return both verified and unverified retirements, with verified ones
      // replacing unverified where there's duplication
      return retirements.concat(
        unverifiedRetirements.filter((r) => !retirementIds.has(r.id))
      );
    },
    {
      enabled: typeof enabled === "undefined" ? true : enabled,
    }
  );
};

export const useRetirementMutation = (
  signer: JsonRpcSigner,
  chainId: ChainId
) => {
  const { proxy } = getContractsForNetwork(chainId);
  const queryClient = useQueryClient();
  return useMutation(
    async (amountInMCut: BigNumber) =>
      proxy.connect(signer).signalRetireIntent(amountInMCut),
    {
      onMutate: () => queryClient.cancelQueries(QueryNamespace.RETIREMENTS),
      // Wallet interactions are pretty quick, but the Ethereum network will
      // take much longer to verify any transactions. Hang on to the
      // (unverified) transaction receipt so that the user can follow its
      // progress.
      // TODO: store the unverified tx off-chain so that it remains across
      //  browser refresh.
      onSuccess: (data, value, context) => {
        queryClient.setQueryData(
          QueryNamespace.RETIREMENTS,
          (oldData: IRetirement[]): IRetirement[] => [
            ...oldData,
            {
              id: data.hash,
              timestamp: new Date(),
              updated: new Date(),
              amount: toCut(value),
              status: RetirementStatuses.UNVERIFIED,
            },
          ]
        );
      },
    }
  );
};
