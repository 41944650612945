import { useAccountQuery, useRetirementMutation } from "../api/account";
import { BigNumber } from "ethers";
import * as React from "react";
import { useState } from "react";
import { toMCutStrict } from "../cut-contract";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { NetworkContext } from "../context/network-context";
import { useTokenQuery } from "../api/token";
import { CutLogoByNetwork } from "./cut-logo";

interface IFormState {
  amount: number | string;
}

export function RetireCarbonForm() {
  const { providerBuilder, signerBuilder, contextLoading } =
    React.useContext(NetworkContext);
  const { provider, chainId, account } = providerBuilder();
  const { signer } = signerBuilder();

  const accountQuery = useAccountQuery(
    provider,
    chainId,
    account,
    !contextLoading
  );

  const retirementMutation = useRetirementMutation(signer, chainId);
  const tokenInfo = useTokenQuery(provider, chainId);
  const cutBalance = accountQuery.isSuccess ? accountQuery.data.cutBalance : 0;
  const [value, setValue] = useState<IFormState>({ amount: "" });
  const [submitting, setSubmitting] = useState(false);
  const [transactionInProgress, setTransactionInProgress] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setError] = useState("");

  const resetForm = () => {
    setError("");
    setValue({ amount: "" });
    setSubmitting(false);
    setTransactionInProgress(false);
  };

  const handleChange = (e) => {
    // Only accept whole numbers
    const amount = e.target.value.replace(/\D/g, "");
    if (!amount || amount === "0") {
      setValue({ amount: "" });
      return;
    }

    // Amount must not exceed CUT balance
    if (amount <= cutBalance) {
      setValue({
        amount: amount.toString(),
      });
    }
  };

  const handleConfirmed = async () => {
    try {
      setTransactionInProgress(true);
      const asMCut = toMCutStrict(BigNumber.from(value.amount));
      await retirementMutation.mutateAsync(asMCut);
      resetForm();
    } catch (e) {
      if (e.code === 4001) {
        // User rejected request
        setError("Transaction cancelled.");
      } else {
        console.error(e);
        setError("Transaction failed. Verify the amount and try again.");
      }
    } finally {
      setTransactionInProgress(false);
      setSubmitting(false);
    }
  };

  const handleCancelled = () => setSubmitting(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setSubmitting(true);
  };

  const isLoading = contextLoading || tokenInfo.isLoading;

  const tokenSymbol = isLoading ? "xCUT" : tokenInfo.data.symbol;

  return (
    <>
      <div className="mt-2 max-w-xl text-sm text-gray-500">
        <p>
          Retire {tokenSymbol}{" "}
          <CutLogoByNetwork
            chainId={chainId}
            className="inline-block shrink-0 self-center h-5 w-5"
          />{" "}
          to permanently remove CO&#8322;e from the atmosphere.{" "}
          <em>(1 {tokenSymbol} = 1 kg CO&#8322;e)</em>{" "}
          <a
            href="https://www.cut.eco/faq"
            target="_blank"
            rel="noreferrer"
            className="font-semibold text-primary-500"
          >
            Learn more <ChevronRightIcon className="h-4 -ml-1.5 inline-block" />
          </a>
        </p>
      </div>
      {transactionInProgress ? (
        <div className="mt-5 sm:flex sm:items-center">
          <div className="text-sm sm:text-left sm:w-full flex align-middle justify-center">
            <div
              style={{ borderTopColor: "rgba(52, 211, 153)" }}
              className="w-5 h-5 mr-2 border-2 border-primary-200 border-solid rounded-full animate-spin inline-block"
            ></div>
            Confirm transaction with wallet...
          </div>
        </div>
      ) : submitting ? (
        <div className="mt-5 sm:flex sm:items-center">
          <div className="text-left text-sm sm:text-left sm:w-full">
            Permanently retire {value.amount.toString()} {tokenSymbol}?
            <br />
            Once confirmed with your wallet this operation cannot be undone.
          </div>
          <div className="flex justify-between ml-0 mt-2 sm:flex-end">
            <button
              color="secondary"
              type="submit"
              onClick={handleCancelled}
              className="btn ml-0"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleConfirmed}
              className="btn btn-primary ml-2"
            >
              Confirm
            </button>
          </div>
        </div>
      ) : (
        <form className="mt-5 sm:flex sm:items-center">
          <div className="w-full sm:max-w-xs">
            <label htmlFor="cut-amount" className="sr-only">
              Amount
            </label>
            <input
              type="text"
              name="amount"
              id="cut-amount"
              onChange={handleChange}
              value={value.amount}
              className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md text-right"
              placeholder="0"
            />
            {/* TODO: Show any errors */}
          </div>
          <div className="flex justify-end sm:block">
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-primary mt-3 sm:mt-0 sm:ml-3"
              disabled={!value.amount || submitting}
            >
              Retire
            </button>
          </div>
        </form>
      )}
    </>
  );
}
