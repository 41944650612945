export function formatNumberShort(num: number, nDigits = 3) {
  if (num >= -1 && num <= 1) {
    return num.toString();
  }
  const SUFFIXES = [
    "", // 0 - < 1e3
    "K", // 1 - < 1e6
    "M", // 2 - < 1e9
    "B", // 3 - < 1e12
    "T", // 4
  ];
  const power10 = Math.log10(Math.abs(num));
  const suffixNum = Math.floor(power10 / 3);
  const suffix = SUFFIXES[suffixNum];
  const suffixPower10 = Math.pow(10, suffixNum * 3);
  const base = num / suffixPower10;
  return `${base.toPrecision(nDigits)}${suffix}`;
}

export function formatNumberLocale(num: number, locale?) {
  return new Intl.NumberFormat(locale, { maximumFractionDigits: 5 }).format(
    num
  );
}

/**
 * Truncates the string to one fewer than the maxColumns and adds an ellipsis.
 */
export function truncateString(aString: string, maxColumns: number) {
  return `${aString.slice(0, maxColumns - 1)}…`;
}
