import { ChainId, Config, DAppProvider } from "@usedapp/core";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ALCHEMY_ETH_API_KEY, ALCHEMY_ARB_API_KEY } from "../api/alchemy";
import { NetworkProvider } from "../components/network-provider";

const defaultQueryClient = new QueryClient();
const dAppConfig: Config = {
  //readOnlyChainId: ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_ETH_API_KEY}`,
    [ChainId.Arbitrum]: `https://arb-mainnet.g.alchemy.com/v2/${ALCHEMY_ARB_API_KEY}`,
  },
};

function AppProviders({ children, queryClient = defaultQueryClient }) {
  return (
    <QueryClientProvider client={queryClient}>
      <DAppProvider config={dAppConfig}>
        <NetworkProvider>
          <BrowserRouter>{children}</BrowserRouter>
        </NetworkProvider>
      </DAppProvider>
    </QueryClientProvider>
  );
}

export default AppProviders;
