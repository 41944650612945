import { ethers } from "ethers";
import { cutABI, cutDbABI, cutProxyABI, projectsDbABI } from "./abis";
import { ChainId } from "@usedapp/core";

/**
 * Addresses for CUT contracts on main and test net(s).
 *
 * There is only one proxy address, and multiple contract addresses. Contract
 * addresses appear in reverse order of when they were deployed (i.e. current
 * production contract appears first).
 */
const networkAddresses = {
  [ChainId.Mainnet]: {
    contracts: ["0xd4CccEc3755de7D4d7a15FED1EB7310f44CfC0aF"],
    projectsDb: "0x94c00E988C9aB4374C0d76484c30aE6D5d7cCe53",
    cutDb: "0x991ef07B7d3fBE8a515432e041EefDb026Fa2ec1",
    proxy: "0x2188110B2C3782a05B077291FC5163f387e9057B",
  },
  [ChainId.Arbitrum]: {
    contracts: ["0xE65b7F6ED62cCdD980E9970fd973Eb8C01c16A44"],
    projectsDb: "0x9089C2Ed112bbb159eC232e50A2e1E38b8aB1294",
    cutDb: "0xBaA2df12Fed5FD8A3068c9b397A0045d85E84c6c",
    proxy: "0x3C9BcBF7e2ea11bd5bcC1B328c795d421d417406"

  },
  [ChainId.Goerli]: {
    contracts: [
      "0x0ec645a1B32eB7aeA4339305A36115931EeACbB8", // latest first
    ],
    projectsDb: "0x750103D12ADCBDc6Ad4Ec9acBc8C38163d964724",
    cutDb: "0xa3D9C5E435CC7bD1b3c338759939B919e8E4CEA8",
    proxy: "0x3C9BcBF7e2ea11bd5bcC1B328c795d421d417406",
  },
};

function isNetworkSupported(chainId: ChainId) {
  return Boolean(networkAddresses[chainId]);
}

interface IContractBundle {
  proxy: ethers.Contract; // Use for all writes, and most reads
  projectsDb: ethers.Contract; // Contains project(s) state
  cutDb: ethers.Contract;
  contracts: ethers.Contract[]; // Use for reads, mainly for logs
}

function getContractsForNetwork(chainId: ChainId): IContractBundle {
  if (!isNetworkSupported(chainId)) {
    return;
  }
  const contracts = networkAddresses[chainId].contracts.map(
    (address) => new ethers.Contract(address, cutABI)
  );
  const projectsDb = new ethers.Contract(
    networkAddresses[chainId].projectsDb,
    projectsDbABI
  );
  const proxy = new ethers.Contract(
    networkAddresses[chainId].proxy,
    cutProxyABI
  );
  const cutDb = new ethers.Contract(
      networkAddresses[chainId].cutDb,
      cutDbABI
  );
  return {
    contracts,
    projectsDb,
    cutDb,
    proxy,
  };
}

export { getContractsForNetwork, isNetworkSupported, networkAddresses };