import {
  BadgeCheckIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import * as React from "react";

export function TransactionConfidenceBadge({ nConfirmations }) {
  if (nConfirmations >= 50) {
    return (
      <span
        title={`${nConfirmations} confirmations - HIGH confidence in transaction`}
      >
        <BadgeCheckIcon className="ml-2 w-6" />
      </span>
    );
  } else if (nConfirmations >= 10) {
    return (
      <span
        title={`Only ${nConfirmations} confirmations - MEDIUM confidence in transaction`}
      >
        <InformationCircleIcon className="ml-2 w-6" />
      </span>
    );
  } else {
    return (
      <span
        title={`Only ${nConfirmations} confirmations - LOW confidence in transaction`}
      >
        <ExclamationIcon className="ml-2 w-6" />
      </span>
    );
  }
}
