import React from "react";
import jazzicon from "@metamask/jazzicon";

/**
 * An identicon for addresses.
 *
 * Uses the same underlying lib, jazzicon, as Metamask, so it should generate
 * a familiar image.
 */
export function Identicon(props: { account: string; diameter: number }) {
  const { account, diameter } = props;
  let shortenedAccount = 0;
  if (account) {
    shortenedAccount = parseInt(account.toLowerCase().slice(2, 10), 16);
  }
  return (
    <div
      style={{
        height: `${diameter}px`,
        width: `${diameter}px`,
        borderRadius: "50%",
        overflow: "hidden",
      }}
      dangerouslySetInnerHTML={{
        __html: jazzicon(diameter, shortenedAccount).innerHTML,
      }}
    />
  );
}
