import React, { useEffect, useRef, useState } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { XIcon } from "@heroicons/react/outline";

interface IHelpTooltipProps {
  title: string;
  description: string;
  link?: string;
}

export function HelpTooltip({ title, description, link }: IHelpTooltipProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent) => {
    if (node.current && node.current.contains(e.target as Node)) {
      // inside click
      return;
    }
    // outside click
    setShowTooltip(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <>
      <div ref={node} className="inline-flex items-center mt-0 ml-1">
        <QuestionMarkCircleIcon
          className="h-4 w-4 text-gray-500 cursor-pointer"
          onClick={() => setShowTooltip(true)}
        />
      </div>
      {showTooltip && (
        <div className="fixed bottom-0 inset-x-0 p0 mx-auto max-w-5xl text-center bg-moss rounded-t">
          <header className="text-white bg-pine p-4 rounded-t">
            <QuestionMarkCircleIcon className="h-6 w-6 absolute top-4 left-4" />
            <XIcon
              className="h-6 w-6 absolute top-4 right-4 cursor-pointer text-white"
              onClick={() => setShowTooltip(false)}
            />
            <h3 className="font-bold text-white">{title}</h3>
          </header>
          <div className={"p-8"}>
            <p className="py-1 text-pine font-light text-left">{description}</p>
            {link && (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold hover:underline"
              >
                Learn more
              </a>
            )}
          </div>
        </div>
      )}
    </>
  );
}
