import * as React from "react";
import { useMemo, useState } from "react";
import { format, formatDistance, subDays } from "date-fns";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import { executeContributionsQuery, IContribution } from "../api/contributions";
import { CutLogoByNetwork, CutLogoGrayscale } from "../components/cut-logo";
import {
  EtherscanAddrLink,
  EtherscanTxLink,
} from "../components/etherscan-link";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/outline";
import { Table } from "../components/table";
import { formatNumberLocale, formatNumberShort } from "../utils/format";
import { combineClassNames } from "../utils/style";
import { getChainColor, getChainName, supportedChains } from "../utils/chain";
import { ChainId } from "@usedapp/core";
import { executeTokenQuery } from "../api/token";
import { toCut } from "../cut-contract";
import { useQueries } from "react-query";
import { constructReadOnlyProvider } from "../api/utils";
import { HelpTooltip } from "../components/help-tooltip";
import { BigNumber } from "ethers";

const EXCHANGE_RATE = 1; // CUT:kg

function ChangeDisplay({ percentage }) {
  const colorClasses =
    percentage > 0
      ? "bg-primary-100 text-primary-800"
      : percentage < 0
      ? "bg-red-100 text-red-800"
      : "bg-gray-100 text-gray-800";

  return (
    <div
      className={combineClassNames(
        "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0",
        colorClasses
      )}
    >
      {percentage > 0 ? (
        <>
          <ArrowSmUpIcon
            className="-ml-1 mr-0.5 shrink-0 self-center h-5 w-5 text-primary-500"
            aria-hidden="true"
          />
          <span className="sr-only">Increased by</span>
        </>
      ) : percentage < 0 ? (
        <>
          <ArrowSmDownIcon
            className="-ml-1 mr-0.5 shrink-0 self-center h-5 w-5 text-red-500"
            aria-hidden="true"
          />
          <span className="sr-only">Decreased by</span>
        </>
      ) : (
        <span className="sr-only">No change</span>
      )}

      {percentage ? `${formatNumberShort(percentage)}%` : "- %"}
    </div>
  );
}

function MetricsSummary({
  retiredAllTime,
  tokens,
  isLoading,
}: {
  retiredAllTime: number;
  tokens: any;
  isLoading: boolean;
}) {
  const initial = {
    livingSupply: BigNumber.from(0),
    retired: BigNumber.from(0),
  };
  const totals = tokens.reduce((total, token) => {
    return isLoading
      ? initial
      : {
          livingSupply: total.livingSupply.add(token.data.livingSupply),
          retired: total.retired.add(token.data.totalRetired),
        };
  }, initial);

  return (
    <div className="max-w-5xl mt-4 mb-0 sm:mt-6 lg:mt-8 mx-auto px-0 sm:px-6 lg:px-8">
      <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 sm:grid-cols-3 md:divide-y-0 md:divide-x">
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">
            CUT Symbols
            <HelpTooltip
              title="CUT Symbols"
              description="The Carbon Utility Token project is deployed on multiple blockchains.
                                     We originally launched on Ethereum in early 2021, and onto Arbitrum in 2023! We
                                     have plans to make CUT available on other networks coming soon as well."
            />
          </dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            {!isLoading
              ? supportedChains.map((chainId, chainIndex) => (
                  <div
                    key={`summary-chain-symbol-${chainId}`}
                    className="inline-flex items-baseline text-2xl font-semibold text-primary-600"
                  >
                    <span
                      style={{
                        color: getChainColor(chainId),
                      }}
                    >
                      {tokens[chainIndex].data.symbol}
                    </span>
                    <CutLogoByNetwork
                      chainId={chainId}
                      className="ml-1 shrink-0 self-center h-5 w-5 mr-6"
                    />
                  </div>
                ))
              : "..."}
          </dd>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">
            Total Living Supply
            <HelpTooltip
              title="Total Living Supply"
              description="The amount of remaining un-retired carbon for the entire CUT project.
                                     This number will fluctuate as more projects are added, more retirements happen,
                                     and more carbon is minted into the networks."
            />
          </dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="inline-flex items-baseline text-2xl font-semibold text-primary-600">
              {!isLoading
                ? formatNumberLocale(toCut(totals.livingSupply))
                : "..."}
              <CutLogoGrayscale className="ml-1 shrink-0 self-center h-5 w-5" />
            </div>
          </dd>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">
            Total Retired
            <HelpTooltip
              title="Total Retired"
              description="The total amount of CUT that has been retired on all networks, and
                                     across all projects. This number will increase as there are more project contributions
                                     and users submit their intents to retire carbon to the network."
            />
          </dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="inline-flex items-baseline text-2xl font-semibold text-primary-600">
              {!isLoading ? formatNumberLocale(toCut(totals.retired)) : "..."}
              <CutLogoGrayscale className="ml-1 shrink-0 self-center h-5 w-5" />
            </div>
          </dd>
        </div>
      </dl>
    </div>
  );
}

function MetricsDetails({
  perChainMetrics,
  providerMap,
  tokenQueries,
  isLoading,
}) {
  const [showDetails, setShowDetails] = useState(false);

  if (isLoading) {
    return (
      <>
        <div className="flex items-center justify-center mt-0 py-0 ">
          <button
            className="inline-flex max-w-sm items-center cursor-pointer
                                   border border-gray-300 border-t-0
                                   text-sm rounded-b px-4 py-2
                                   text-gray-500
                                   hover:text-gray-900"
          >
            Loading ...
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="flex items-center justify-center mt-0 py-0 ">
        <button
          onClick={() => setShowDetails(!showDetails)}
          className="inline-flex max-w-sm items-center cursor-pointer
                                   border border-gray-300 border-t-0
                                   text-sm rounded-b px-4 py-2
                                   text-gray-500
                                   hover:text-gray-900"
        >
          <span className="">Show {showDetails ? "Less" : "More"}</span>
          {showDetails ? (
            <ChevronDoubleUpIcon className="h-5 w-5 ml-1 text-gray-500" />
          ) : (
            <ChevronDoubleDownIcon className="h-5 w-5 ml-1 text-gray-500" />
          )}
        </button>
      </div>
      {showDetails &&
        supportedChains.map((chainId, chainIndex) => {
          return (
            <div key={`metric-${chainId}`}>
              <Metrics
                retiredAllTime={perChainMetrics[chainId].total}
                retiredLast30={perChainMetrics[chainId].thirty}
                retiredPrev30={perChainMetrics[chainId].sixty}
                provider={providerMap[chainId]}
                chainId={chainId}
                tokenData={tokenQueries[chainIndex].data}
              />
            </div>
          );
        })}
    </>
  );
}

function Metrics({
  retiredAllTime,
  retiredLast30,
  retiredPrev30,
  provider,
  chainId,
  tokenData,
}: {
  retiredAllTime?: number;
  retiredLast30?: number;
  retiredPrev30?: number;
  provider: any;
  chainId: ChainId;
  tokenData: any;
}) {
  const changeLast30 =
    retiredPrev30 && retiredPrev30 > 0
      ? ((retiredLast30 - retiredPrev30) / retiredPrev30) * 100
      : null;
  return (
    <div className="max-w-5xl my-4 sm:my-6 lg:my-8 mx-auto px-0 sm:px-6 lg:px-8">
      <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 sm:grid-cols-4 md:divide-y-0 md:divide-x">
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">
            Network
            <HelpTooltip
              title="Network"
              description="The blockchain that the retirement contracts are deployed to."
            />
          </dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="inline-flex items-baseline text-2xl font-semibold text-primary-600">
              <span
                style={{
                  color: getChainColor(chainId),
                }}
              >
                {getChainName(chainId)}
              </span>
            </div>
          </dd>
          <dt className="text-base font-normal text-gray-900 mt-6">
            Symbol
            <HelpTooltip
              title="Symbol"
              description="The network symbol or ticker symbol for the contract. This is unique
                                     per network so the carbon retired and their projects can be easily distinguished."
            />
          </dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="inline-flex items-baseline text-2xl font-semibold text-primary-600">
              {tokenData.symbol}
              <CutLogoByNetwork
                chainId={chainId}
                className="ml-1 shrink-0 self-center h-5 w-5"
              />
            </div>
          </dd>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">
            Living Supply
            <HelpTooltip
              title="Living Supply"
              description="The amount of remaining un-retired carbon in the contract.
                                     This number will fluctuate as more projects are added, more retirements happen,
                                     and more carbon is minted into the network."
            />
          </dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="inline-flex items-baseline text-2xl font-semibold text-primary-600">
              {formatNumberLocale(toCut(tokenData.livingSupply))}
            </div>
          </dd>
          <dt className="text-base font-normal text-gray-900 mt-6">
            Remaining Tokens
            <HelpTooltip
              title="Remaining Tokens"
              description="The number of tokens available for dispersing that are still held in
                                     the contract. If this number is much lower than the Living Supply, that means
                                     there are a number of people or exchanges currently holding CUT before it's retired."
            />
          </dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="inline-flex items-baseline text-2xl font-semibold text-primary-600">
              {formatNumberLocale(toCut(tokenData.unmatchedAvailable))}
            </div>
          </dd>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">
            Historical Supply
            <HelpTooltip
              title="Historical Supply"
              description="The amount of carbon ever minted to any project on this network.
                                     This number tracks the total amount of all time post retirements and regardless
                                     of how many people are holding carbon. This number can and will exceed
                                     the soft cap for the project as the goal of CUT is to continuously retire our
                                     carbon and replenish it by supporting more carbon offset projects."
            />
          </dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="inline-flex items-baseline text-2xl font-semibold text-primary-600">
              {formatNumberLocale(toCut(tokenData.historicalSupply))}
            </div>
          </dd>
          <dt className="text-base font-normal text-gray-900 mt-6">
            Retired (all-time)
          </dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="inline-flex items-baseline text-2xl font-semibold text-primary-600">
              {retiredAllTime ? formatNumberLocale(retiredAllTime) : "-"}
            </div>
          </dd>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">
            Conversion Rate
          </dt>
          <div className="inline-flex items-baseline text-2xl font-semibold text-primary-600">
            {1 / EXCHANGE_RATE} kg CO₂e
          </div>
          <dt className="text-base font-normal text-gray-900 mt-6">
            Retired (last 30 days)
          </dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="inline-flex items-baseline text-2xl font-semibold text-primary-600">
              {retiredLast30 ? formatNumberLocale(retiredLast30) : "-"}
            </div>
          </dd>
        </div>
      </dl>
    </div>
  );
}

export const NetworkScreen = () => {
  const now = useMemo(() => new Date(), []);

  const providerMap = useMemo(() => {
    return supportedChains.reduce((map, chainId) => {
      map[chainId] = constructReadOnlyProvider(chainId);
      return map;
    }, {});
  }, []);

  const contributionQueries = useQueries(
    supportedChains.map((chainId) => {
      return {
        queryKey: ["contributions", chainId],
        queryFn: () =>
          executeContributionsQuery(providerMap[chainId], chainId, null),
        refetchInterval: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
      };
    })
  );

  const tokenQueries = useQueries(
    supportedChains.map((chainId) => {
      return {
        queryKey: ["token", chainId],
        queryFn: () => executeTokenQuery(providerMap[chainId], chainId),
        refetchOnWindowFocus: false,
        refetchInterval: 5 * 60 * 1000,
      };
    })
  );

  const { contributionSummary, perChainMetrics } = useMemo(() => {
    const thirtyDaysAgo = subDays(now, 30);
    const sixtyDaysAgo = subDays(now, 60);

    const initial = {
      thirty: 0,
      sixty: 0,
      total: 0,
    };

    const contributionSummary = {
      thirty: 0,
      sixty: 0,
      total: 0,
    };

    const perChainMetrics = supportedChains.reduce(
      (map, chainId, chainIndex) => {
        const contributions = contributionQueries[chainIndex].data;

        if (!contributions) return initial;

        map[chainId] = contributions.reduce((metrics, contribution) => {
          let thirty = 0;
          let sixty = 0;
          if (contribution.timestamp > thirtyDaysAgo) {
            thirty = contribution.amount;
          } else if (contribution.timestamp > sixtyDaysAgo) {
            sixty = contribution.amount;
          }

          contributionSummary.thirty = contributionSummary.thirty + thirty;
          contributionSummary.sixty = contributionSummary.sixty + sixty;
          contributionSummary.total =
            contributionSummary.total + contribution.amount;

          return {
            thirty: metrics.thirty + thirty,
            sixty: metrics.sixty + sixty,
            total: metrics.total + contribution.amount,
          };
        }, initial);

        return map;
      },
      {}
    );

    return { contributionSummary, perChainMetrics };
  }, [contributionQueries, now]);

  const combinedContributions = useMemo(() => {
    const initial: IContribution[] = [];
    const hydratedContributions = supportedChains.reduce(
      (contributions: IContribution[], chainId, chainIndex) => {
        const chainContributions = contributionQueries[chainIndex].data;

        if (!chainContributions) return initial;

        return [
          ...contributions,
          ...chainContributions.map((contribution) => {
            contribution.chainId = chainId;
            return contribution;
          }),
        ];
      },
      []
    );

    return hydratedContributions.sort((a, b) => {
      return a.timestamp.getTime() - b.timestamp.getTime();
    });
  }, [contributionQueries]);

  const columns = useMemo(
    () => [
      {
        Header: "Age",
        accessor: "timestamp",
        Cell: ({ value }) => (
          <span title={format(value, "yyyy-MM-dd")}>
            {formatDistance(value, now)}
          </span>
        ),
      },
      {
        Header: "Chain",
        accessor: "chainId",
        Cell: ({ value }) => (
          <span
            style={{
              color: getChainColor(value),
            }}
          >
            {getChainName(value)}
          </span>
        ),
      },
      {
        Header: "Tx",
        accessor: "id",
        Cell: ({ row, value }) => (
          <EtherscanTxLink txnHash={value} chainId={row.original.chainId} />
        ),
      },
      {
        Header: "Contributor",
        accessor: "contributor",
        Cell: ({ row, value }) => (
          <EtherscanAddrLink address={value} chainId={row.original.chainId} />
        ),
      },
      {
        Header: "Amount",
        accessor: "amount",
        headerClassName: "text-right",
        cellClassName: "text-right",
        Cell: ({ row, value }) => (
          <div className="inline-flex items-baseline text-gray-600">
            {formatNumberLocale(value)}{" "}
            <CutLogoByNetwork
              chainId={row.original.chainId}
              className="ml-2 shrink-0 self-center h-5 w-5"
            />
          </div>
        ),
      },
    ],
    [now]
  );

  const isLoading =
    contributionQueries.some((query) => query.isLoading) ||
    tokenQueries.some((query) => query.isLoading);

  return (
    <>
      <MetricsSummary
        retiredAllTime={contributionSummary.total}
        tokens={tokenQueries}
        isLoading={isLoading}
      />

      <MetricsDetails
        perChainMetrics={perChainMetrics}
        providerMap={providerMap}
        tokenQueries={tokenQueries}
        isLoading={isLoading}
      />

      <div className="max-w-5xl mx-auto px-0 sm:px-6 lg:px-8">
        <div className="py-5 px-4 sm:px-0">
          <h2 className="text-2xl leading-6 font-medium text-pine">
            Retirements
          </h2>
        </div>
      </div>
      <div className="max-w-5xl mx-auto px-0 sm:px-6 lg:px-8">
        <Table
          columns={columns}
          data={combinedContributions}
          ariaLabel="contributions"
          initialSortBy={[{ id: "timestamp", desc: true }]}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
