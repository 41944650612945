import { NetworkContext } from "../context/network-context";
import React from "react";
import { ChainId, useEthers } from "@usedapp/core";
import { ethers } from "ethers";
import { ALCHEMY_ARB_API_KEY, ALCHEMY_ETH_API_KEY } from "../api/alchemy";
import { BaseProvider, JsonRpcSigner } from "@ethersproject/providers";

export function NetworkProvider({ children }) {
  const LOCAL_STORAGE_KEY = "selectedNetwork";
  const {
    switchNetwork,
    account,
    active,
    chainId: initialChainId,
    isLoading: ethersIsLoading,
    library,
  } = useEthers();
  const [selectedNetwork, setSelectedNetwork] = React.useState(null);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!ethersIsLoading && active && initialChainId) {
      setSelectedNetwork(initialChainId);
      setLoading(false);
    }
  }, [initialChainId, active, setLoading, ethersIsLoading]);

  const handleSetSelectedNetwork = React.useCallback(
    async (chainId) => {
      // Then call switchNetwork
      try {
        if (active) {
          await switchNetwork(chainId);
        }
        setSelectedNetwork(chainId);
      } catch (error) {
        console.error("Failed to switch network:", error);
      }
    },
    [active, switchNetwork]
  );

  const constructProvider = (): {
    provider: BaseProvider;
    chainId: ChainId;
    account: string;
  } => {
    let chainId = selectedNetwork ? selectedNetwork : ChainId.Mainnet;
    const isArb = chainId === ChainId.Arbitrum;
    const provider = active
      ? new ethers.providers.Web3Provider(window.ethereum)
      : new ethers.providers.AlchemyProvider(
          chainId,
          isArb ? ALCHEMY_ARB_API_KEY : ALCHEMY_ETH_API_KEY
        );

    return { provider, chainId, account };
  };

  const constructSigner = (): {
    signer: JsonRpcSigner;
    chainId: ChainId;
    account: string;
  } => {
    let chainId = selectedNetwork ? selectedNetwork : ChainId.Mainnet;
    const signer = library.getSigner(account);
    return { signer, chainId, account };
  };

  if (loading && ethersIsLoading) {
    return <div>Loading...</div>; // or some loading spinner
  }

  return (
    <NetworkContext.Provider
      value={{
        selectedNetwork,
        setSelectedNetwork: handleSetSelectedNetwork,
        providerBuilder: constructProvider,
        signerBuilder: constructSigner,
        contextLoading: loading,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
}
