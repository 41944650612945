import { useQuery } from "react-query";
import { JsonRpcProvider } from "@ethersproject/providers";
import { ChainId } from "@usedapp/core";
import { getContractsForNetwork } from "../cut-contract/addresses";
import { BigNumber } from "ethers";

export async function executeTokenQuery(
  provider: JsonRpcProvider,
  chainId: ChainId
) {
  const { contracts, proxy, cutDb } = getContractsForNetwork(chainId);

  const connectedProxy = proxy.connect(provider);
  const connectedCutDb = cutDb.connect(provider);
  const symbol: String = await connectedProxy.symbol();
  const name: String = await connectedProxy.name();
  const unmatchedAvailable: BigNumber =
    await connectedCutDb.unmatchedAvailable();
  const livingSupply: BigNumber = await connectedCutDb.livingSupply();
  const totalRetired: BigNumber = await connectedCutDb.totalRetired();
  const historicalSupply: BigNumber = await connectedCutDb.historicalSupply();

  return {
    symbol,
    name,
    unmatchedAvailable,
    livingSupply,
    totalRetired,
    historicalSupply,
  };
}

export const useTokenQuery = (provider: JsonRpcProvider, chainId: ChainId) => {
  return useQuery([chainId], async () => {
    return executeTokenQuery(provider, chainId);
  });
};
