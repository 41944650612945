import * as React from "react";
import { ChainId } from "@usedapp/core";

/**
 * Full color CUT Logo with "CUT" text
 * @param className
 * @constructor
 */
export function CutLogoTypeColor({ className }: { className?: string }) {
  return (
    <img className={className} src="/cut-logo.svg" alt="Carbon Utility Token" />
  );
}

export function CutLogoColor({ className }: { className?: string }) {
  return (
    <img
      className={className}
      src="/cut-glyph.svg"
      alt="Carbon Utility Token"
    />
  );
}

export function CutLogoByNetwork({
  chainId,
  className,
}: {
  chainId: ChainId;
  className?: string;
}) {
  if (chainId === ChainId.Arbitrum) {
    return CutLogoArbitrum({ className });
  } else if (chainId === ChainId.Mainnet) {
    return CutLogoColor({ className });
  }

  // default to grayscale.
  return CutLogoGrayscale({ className });
}

export function CutLogoArbitrum({ className }: { className?: string }) {
  return (
    <img
      className={className}
      src="/acut-glyph.svg"
      alt="Carbon Utility Token"
    />
  );
}

export function CutLogoGrayscale({ className }: { className?: string }) {
  return (
    <img
      className={className}
      src="/cut-glyph-gray.svg"
      alt="Carbon Utility Token"
    />
  );
}

export function CutLogoWhite({ className }: { className?: string }) {
  return (
    <img
      className={className}
      src="/cut-glyph-white.svg"
      alt="Carbon Utility Token"
    />
  );
}

export function CutLogoBlack({ className }: { className?: string }) {
  return (
    <img
      className={className}
      src="/cut-glyph-black.svg"
      alt="Carbon Utility Token"
    />
  );
}
