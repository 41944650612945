import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import { CutLogoByNetwork, CutLogoWhite } from "../components/cut-logo";
import { EtherscanTxLink } from "../components/etherscan-link";
import { useContributionsQuery } from "../api/contributions";
import { find } from "lodash";
import { intlFormat } from "date-fns";
import { formatNumberLocale } from "../utils/format";
import { getProject } from "../api/projects";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useQuery } from "react-query";
import { TransactionConfidenceBadge } from "../components/transaction-confidence-badge";
import { NetworkContext } from "../context/network-context";
import { ChainId } from "@usedapp/core";
import { JsonRpcProvider } from "@ethersproject/providers";
import { constructReadOnlyProvider } from "../api/utils";

function getAppropriateProvider(
  providerBuilder,
  networkPreselect: string
): {
  provider: JsonRpcProvider;
  chainId: ChainId;
} {
  if (networkPreselect) {
    // we want to serialize Mainnet as Ethereum for clarity.
    const proposedNetwork =
      networkPreselect === "Ethereum" ? "Mainnet" : networkPreselect;
    // check if the provided url value is even a valid network.
    if (proposedNetwork in ChainId) {
      const chainId = ChainId[proposedNetwork as keyof typeof ChainId];
      const provider = constructReadOnlyProvider(chainId);
      return { provider, chainId };
    }
  }

  const { provider, chainId } = providerBuilder();
  return { provider, chainId };
}


export function CertificateScreen(props) {
  const { networkPreselect, contributionId } = useParams();
  const { providerBuilder } = React.useContext(NetworkContext);
  const { provider, chainId } = getAppropriateProvider(
    providerBuilder,
    networkPreselect
  );

  const contributionsQuery = useContributionsQuery(provider, chainId);
  const contribution = find(contributionsQuery.data, { id: contributionId });
  const transactionQuery = useQuery(
    ["transaction", contribution?.id],
    async () => {
      return await contribution.event.getTransaction();
    },
    { enabled: !contributionsQuery.isLoading && !!contribution?.id }
  );
  const navigate = useNavigate();
  if (transactionQuery.isIdle || transactionQuery.isLoading) {
    return null;
  }
  const confirmations = transactionQuery.data.confirmations;
  const project = getProject(chainId, contribution.project);
  return (
    <div className="max-w-5xl my-4 sm:my-6 lg:my-8 mx-auto px-0 sm:px-6 lg:px-8">
      <nav className="flex items-center mb-2 print:hidden">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center mb-2 text-gray-500 hover:text-gray-700"
        >
          <ArrowLeftIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          <span className="ml-2 text-sm font-medium ">Go Back</span>
        </button>
      </nav>
      <div className="rounded-xl shadow-lg bg-white overflow-hidden border-gray-100 border print-color-exact">
        <div className="p-8">
          <div className="flex mr-8 flex-col items-start">
            <div className="flex w-full justify-between">
              <div>
                <div className="text-primary-900 text-lg uppercase">
                  <dl className="flex">
                    <dt className="mr-2 font-extrabold">Date:</dt>
                    <dd>{intlFormat(contribution.timestamp)}</dd>
                  </dl>
                </div>
                <div className="text-primary-900 font-extrabold uppercase">
                  <div className="text-3xl tracking-tight">
                    Carbon Retirement
                  </div>
                  <div className="text-5xl tracking-wide mb-4">Certificate</div>
                </div>
              </div>
              <CutLogoByNetwork chainId={chainId} className="w-20" />
            </div>
            <div className="flex w-full">
              <dl className="w-full">
                <div className="mb-4">
                  <dt className="text-primary-900 uppercase font-bold">
                    Presented to:
                  </dt>
                  <dd className="border-b-2 border-primary-900 mb-2">
                    {contribution.contributor}
                  </dd>
                </div>

                <div className="mb-4">
                  <dt className="text-primary-900 uppercase font-bold">
                    For retiring:
                  </dt>
                  <dd className="border-b-2 border-primary-900">
                    {formatNumberLocale(contribution.amount)} kg CO<sub>2</sub>e
                  </dd>
                </div>

                <div className="mb-4">
                  <dt className="text-primary-900 uppercase font-bold">
                    From Offset project:
                  </dt>
                  <dd className="border-b-2 border-primary-900">
                    {project.name}
                  </dd>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <dt className="text-primary-900 uppercase font-bold">
                      Project type:
                    </dt>
                    <dd className="border-b-2 border-primary-900">
                      {project.projectType}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-primary-900 uppercase font-bold">
                      Certification protocol:
                    </dt>
                    <dd className="border-b-2 border-primary-900">
                      {project.certificationProtocol}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-primary-900 uppercase font-bold">
                      Project location:
                    </dt>
                    <dd className="border-b-2 border-primary-900">
                      {project.reductionsLocation}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-primary-900 uppercase font-bold">
                      Verifier:
                    </dt>
                    <dd className="border-b-2 border-primary-900 truncate">
                      {project.verifier}
                    </dd>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>

        <footer className="flex bg-primary-900 text-white items-center p-5">
          <CutLogoWhite className="h-12 w-12 mr-4" />
          <dl className="truncate">
            <div className="flex">
              <dt className="uppercase font-bold mr-1">Tx:</dt>
              <dd className="flex truncate">
                <EtherscanTxLink
                  txnHash={contributionId}
                  chainId={chainId}
                  truncate={false}
                />
                <TransactionConfidenceBadge nConfirmations={confirmations} />
              </dd>
            </div>
          </dl>
        </footer>
      </div>
    </div>
  );
}
