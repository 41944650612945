import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { ChainId } from "@usedapp/core";
import { getChainColor, getChainName, supportedChains } from "../utils/chain";
import { NetworkContext } from "../context/network-context";
import { WifiIcon } from "@heroicons/react/solid";
import { matchPath, useLocation } from "react-router-dom";
import { some } from "lodash";

interface INetworkMenuProps {
  deactivate: () => void;
}

export function NetworkMenuDesktop({ deactivate }: INetworkMenuProps) {
  const { selectedNetwork, setSelectedNetwork } =
    React.useContext(NetworkContext);

  const location = useLocation();
  const hiddenRoutes = ["/", "/network/", "/projects/:network/:projectId"];

  // Check if the current location matches the project routes pattern
  const matches = hiddenRoutes.map((routeString) => {
    return matchPath(routeString, location.pathname);
  });

  if (some(matches)) {
    return null;
  }

  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700">
          <span
            style={{
              color: selectedNetwork
                ? getChainColor(selectedNetwork)
                : "inherit",
            }}
          >
            {selectedNetwork ? getChainName(selectedNetwork) : "Select Network"}
          </span>
          <WifiIcon className="ml-2 w-4"></WifiIcon>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 top-1.5 mt-9 w-48 rounded-br-md rounded-bl-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {supportedChains.map((networkChainId) => {
            const color = getChainColor(networkChainId);
            return (
              <Menu.Item key={networkChainId}>
                {({ active }) => (
                  <button
                    onClick={() => setSelectedNetwork(networkChainId)}
                    className={`block w-full text-left px-4 py-2 text-sm ${
                      selectedNetwork === networkChainId // Step 3
                        ? "bg-yellow-300 text-gray-700"
                        : active
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-700"
                    }`}
                    style={{
                      backgroundColor:
                        selectedNetwork === networkChainId ? color : "inherit",
                      color:
                        selectedNetwork === networkChainId ? "#FFF" : "inherit",
                    }}
                  >
                    {getChainName(networkChainId)}
                  </button>
                )}
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export function NetworkMenuMobile({ deactivate }: INetworkMenuProps) {
  const { selectedNetwork, setSelectedNetwork } =
    React.useContext(NetworkContext);
  const supportedChains = [ChainId.Mainnet, ChainId.Arbitrum];
  return (
    <>
      <Menu>
        <div className="flex items-center px-4">
          <div className="block w-full px-0 py-2 text-left text-base font-medium text-gray-500 truncate">
            Network:
          </div>
          {supportedChains.map((networkChainId) => {
            const color = getChainColor(networkChainId);
            return (
              <Menu.Item key={networkChainId}>
                {({ active }) => (
                  <button
                    onClick={() => setSelectedNetwork(networkChainId)}
                    className={`block w-full text-left px-4 py-2 text-sm rounded-md ${
                      selectedNetwork === networkChainId // Step 3
                        ? "text-gray-700"
                        : active
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-700"
                    }`}
                    style={{
                      backgroundColor:
                        selectedNetwork === networkChainId ? color : "inherit",
                      color:
                        selectedNetwork === networkChainId ? "#FFF" : "inherit",
                    }}
                  >
                    {getChainName(networkChainId)}
                  </button>
                )}
              </Menu.Item>
            );
          })}
        </div>
      </Menu>
    </>
  );
}
