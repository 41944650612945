import { BigNumber, ethers } from "ethers";
import { BaseProvider } from "@ethersproject/providers";
import { ChainId } from "@usedapp/core";
import { flatten, sortBy } from "lodash";
import { getContractsForNetwork } from "../cut-contract/addresses";
import { ALCHEMY_ARB_API_KEY, ALCHEMY_ETH_API_KEY } from "./alchemy";

export const constructReadOnlyProvider = (chainId: ChainId) => {
  const isArb = chainId === ChainId.Arbitrum;
  return new ethers.providers.AlchemyProvider(
    chainId,
    isArb ? ALCHEMY_ARB_API_KEY : ALCHEMY_ETH_API_KEY
  );
};

/**
 * Matches intents with contributions based on amount, in block order
 */
export const matchIntentsWithContributions = (
  intents: ethers.Event[],
  contributions: ethers.Event[]
) => {
  // Build a mapping of amount -> contribution
  // If there is more than one contribution with the same amount, they will
  // be appended in block order, so that they can be matched with intents
  // (roughly) chronologically.
  const mapping = new Map<string, ethers.Event[]>();
  contributions.forEach((contribution) => {
    const key = (contribution.args[2] as BigNumber).toString();
    const currentValue = mapping.has(key)
      ? mapping.get(key).concat(contribution)
      : [contribution];
    mapping.set(key, currentValue);
  });

  // The number of intents should always be >= the number of contributions
  return intents.map((intent) => {
    const key = (intent.args[1] as BigNumber).toString();
    return {
      intent,
      contribution: mapping.has(key) ? mapping.get(key).shift() : undefined,
    };
  });
};

interface IContributionFilterArgs {
  owner?: string;
  project?: number;
  amount?: BigNumber;
}

export async function fetchContributions(
  provider: BaseProvider,
  chainId: ChainId,
  filterArgs = {}
) {
  const { contracts } = getContractsForNetwork(chainId);
  const { owner, project, amount }: IContributionFilterArgs = filterArgs;
  const events = await Promise.all(
    contracts.map((c) => {
      return c
        .connect(provider)
        .queryFilter(c.filters.Contribution(owner, project, amount));
    })
  );
  return sortBy(flatten(events), "blockNumber");
}

interface IRetireIntentFilterArgs {
  owner?: string;
  amount?: BigNumber;
}

export async function fetchRetireIntents(
  provider: BaseProvider,
  chainId: ChainId,
  filterArgs = {}
) {
  const { contracts } = getContractsForNetwork(chainId);
  const { owner, amount }: IRetireIntentFilterArgs = filterArgs;
  const events = await Promise.all(
    contracts.map((c) => {
      return c
        .connect(provider)
        .queryFilter(c.filters.RetireIntent(owner, amount));
    })
  );
  return sortBy(flatten(events), "blockNumber");
}
