import { BigNumber } from "ethers";
import { IRetirement } from "../api/account";

const MCUT_DIVISOR = 1e5;

/**
 * Converts mCUT to CUT
 *
 * Returns a number, not BigNumber, to allow for fractional amounts.
 */
function toCut(mCut: BigNumber): number {
  return mCut.toNumber() / MCUT_DIVISOR;
}

/**
 * Converts CUT to mCUT
 *
 * Allows fractional CUT as input.
 */
function toMCut(cut: number): BigNumber {
  return BigNumber.from(cut * MCUT_DIVISOR);
}

/**
 * Converts whole CUT to mCUT
 *
 * When CUT is represented as a BigNumber it won't have any fractional
 * component, so only whole CUT is accepted as input. Dubbed "strict" because
 * the input type is the same as the output type.
 */
function toMCutStrict(cut: BigNumber): BigNumber {
  return cut.mul(MCUT_DIVISOR);
}

/**
 * Computes the amount of CUT retired in CUT and CO2e
 *
 * Uses a static 1:1 CUT:CO2e conversion, and assumes all (un)verified intents
 * will (shortly) be converted to contributions.
 */
function computeAmountRetired(retirements: IRetirement[]): {
  asCut: number;
  asKg: number;
} {
  const amountRetired = retirements
    ?.map(({ amount }) => amount)
    .reduce((total, amount) => amount + total, 0);
  return {
    asCut: amountRetired,
    asKg: amountRetired,
  };
}

export { computeAmountRetired, toCut, toMCut, toMCutStrict };
