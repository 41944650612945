import { ChainId, Mainnet, Goerli, Arbitrum } from "@usedapp/core";
import { truncateString } from "../utils/format";

const supportedChains = {
  [ChainId.Goerli]: Goerli,
  [ChainId.Mainnet]: Mainnet,
  [ChainId.Arbitrum]: Arbitrum,
};

const EtherscanLink = ({
  href,
  textContent,
  truncate = true,
  maxColumns = 20,
}: {
  href: string;
  textContent: string;
  truncate?: boolean;
  maxColumns?: number;
}) => {
  return (
    <a
      href={href}
      title={textContent}
      className="font-semibold hover:underline"
      target="_blank"
      rel="noreferrer"
    >
      {truncate ? truncateString(textContent, maxColumns) : textContent}
    </a>
  );
};

export const EtherscanAddrLink = ({
  address,
  chainId,
  truncate = true,
  maxColumns = 20,
}: {
  address: string;
  chainId?: ChainId;
  truncate?: boolean;
  maxColumns?: number;
}) => {
  if (!address) {
    return null;
  }
  const chain = chainId ? supportedChains[chainId] : Mainnet;
  const href = chain?.getExplorerAddressLink(address);
  return (
    <EtherscanLink
      href={href}
      textContent={address}
      maxColumns={maxColumns}
      truncate={truncate}
    />
  );
};

export const EtherscanTxLink = ({
  txnHash,
  chainId,
  truncate = true,
  maxColumns = 20,
}: {
  chainId?: ChainId;
  txnHash: string;
  truncate?: boolean;
  maxColumns?: number;
}) => {
  if (!txnHash) {
    return null;
  }
  const chain = chainId ? supportedChains[chainId] : Mainnet;
  const href = chain?.getExplorerTransactionLink(txnHash);
  return (
    <EtherscanLink
      href={href}
      textContent={txnHash}
      maxColumns={maxColumns}
      truncate={truncate}
    />
  );
};
